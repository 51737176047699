<template>
  <div class="list">
    <div class="section-header">
      <div class="section-header--bread">
        <router-link to="/">Головна</router-link>
        <p>|</p>
        <a>Черга</a>
      </div>
      <h3>{{ name }}</h3>
    </div>

    <div class="section">
      <Loader v-if="list.length == 0" />

      <ul v-else>
        <li v-for="(item, index) in list" :key="index">
          <div class="card">
            <p>{{ item.номер }}</p>

            <div class="card-body">
              <div class="card-part">
                <span>П.І.Б.</span>
                <p>{{ item.прізвище }}</p>
              </div>
              <div class="card-part" v-if="item.склад">
                <span>Склад сім'ї</span>
                <p>{{ item.склад }}</p>
              </div>
              <div class="card-part" v-if="item.датазарахуваннянаоблік">
                <span>Дата зарахування</span>
                <p>{{ item.датазарахуваннянаоблік }}</p>
              </div>
              <div class="card-part" v-if="item.група">
                <span>Група</span>
                <p>{{ item.група }}</p>
              </div>
              <div class="card-part" v-if="item.адеса">
                <span>Адеса</span>
                <p>{{ item.адеса }}</p>
              </div>
              <div class="card-part" v-if="item.кадастровий">
                <span>Кадастровий номер</span>
                <p>{{ item.кадастровий }}</p>
              </div>
              <div class="card-part" v-if="item.дата">
                <span>Підстава</span>
                <p>{{ item.дата }}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import { getData } from "../store/dates.js";
  import lists from "@/store/list.js";

  import { reactive, computed, onBeforeMount } from "vue";
  import { useRoute } from "vue-router";

  import Loader from "../components/global/Loader.vue";

  export default {
    name: "List",

    components: { Loader },

    setup() {
      const list = reactive([]);

      const route = useRoute();
      const category = computed(() => route.query.category);
      const name = computed(() => lists.names[category.value - 1]);

      onBeforeMount(() => fetchList());

      const fetchList = async () => {
        try {
          let get = await axios.get(getData(category.value)),
            header = get.data.values[0],
            rows = get.data.values.slice(1);

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });
            list.push(formattedRow);
          }

          window.scrollTo(0, 0);
        } catch (e) {
          console.log(e);
        }
      };
      return { list, name };
    },
  };
</script>
<style lang="scss" scoped></style>
